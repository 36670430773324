import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import { Main } from "../components/sections/Content"
import { DivDefaultWrapper } from "../components/parts/General"
import { Title } from "../components/parts/General"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Ul = styled.ul`
  padding: 0;
  list-style: none;

  li {
    margin: 0 0 35px 0;
  }
`

const FAQPage = () => {
  return (
    <Layout>
      <Main>
        <DivDefaultWrapper id="FAQ" as="section">
          <Title>Frequently Asked Questions</Title>
          <Ul>
            <li>
              <h2>When will the soundtrack be released?</h2>
              <p>
                The release date has not been announced yet. Once everything is
                mixed and mastered, a date can be picked, but either December 2024 or January 2025 is looking very likely!
              </p>
            </li>
            <li>
              <h2>Where will I be able to buy or listen to the soundtrack?</h2>
              <p>
                The CD will be available on Candy's Bandcamp page and the Wildermyth merch store. Digital purchases will be available at Bandcamp, Steam, iTunes, Amazon, and other big and small digital retailers. It will also be available for streaming on Spotify and a myriad of other services. The distribution service Candy is using has a very long list of sites, so the chances are very high that you'll be able to purchase or listen to the music wherever you like best.
              </p>
            </li>
            <li>
              <h2>Where can I listen to the soundtrack now?</h2>
              <p>
                The recordings that are currently in the game (which are made
                primarily with sample libraries) are available for listening on
                the{" "}
                <a href="https://soundcloud.com/wildermyth">
                  Wildermyth Soundcloud
                </a>
              </p>
            </li>
			<li>
              <h2>
                Will the new recordings be put in the game?
              </h2>
              <p>
               The new recordings will be implemented in the PC version of Wildermyth! We're also planning to implement a toggle so you can switch between the original and new recordings. At this moment, there are no current plans to add the new recordings to the console editions of Wildermyth. We'd love to see the live tracks added to consoles as well, but any updates to the console versions will be decided upon by Auroch Digital.
              </p>
            </li>
            <li>
              <h2>
                Is there any way to purchase the rewards from the Kickstarter
                campaign?
              </h2>
              <p>
                Not currently - the music boxes are now
                sold out. There are some surplus pins which may be sold or used
                for giveaways but are not currently available for purchase. New
                production of music boxes may or may not happen in the future,
                but the pins were exclusive for the kickstarter campaign, so
                once they're gone they'll be gone forever.
              </p>
            </li>
            <li>
              <h2>
                Can I use the music in my podcast/D&D campaign/YouTube
                video/other thing?
              </h2>
              <p>
                For let's play videos or streams and other situations where the
                music is included as a part of gameplay footage, yes! For all
                other uses: maybe! Please{" "}
                <Link to="/contact">contact Candy</Link> with information about
                your specific scenario and she'll get back to you.
              </p>
            </li>
            <li>
              <h2>
                Is the soundtrack being released through a publishing company?
              </h2>
              <p>
                No. When the kickstarter campaign ran, the soundtrack was intended
                to be released through a specific publishing company. After the
                campaign ended and shortly before Candy was about to sign the
                contract, major concerns about the company's integrity were
                publicly revealed and she decided to move forward without them.
                Candy will self-publish and distribute the album through a third-party service.
              </p>
            </li>
          </Ul>
        </DivDefaultWrapper>
      </Main>
    </Layout>
  )
}

export default FAQPage

export const Head = () => <Seo title="Frequently Asked Questions" />
